<script lang="ts" setup>
	import { useUser } from "@/stores/user";
	import { useFilters } from "@/stores/filters";

	useHead({
		title: "LeadX",
		meta: [{ name: "description", content: "" }],
	});

	const { load: loadUser } = useUser();
	const { load: loadFilters } = useFilters();

	if (process.server) {
		await Promise.all([loadUser(), loadFilters()]);
	}
</script>

<template>
	<div class="authenticated">
		<Navigation />

		<div class="content">
			<Header />

			<main><slot /></main>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.authenticated {
		background: radial-gradient(72.29% 72.29% at 50% 0%, $red_semi 0%, rgba(246, 248, 254, 0) 100%);

		display: flex;
		width: 100%;
		height: 100vh;
		max-height: 100vh;
		flex-direction: row;

		.content {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			header {
				flex-shrink: 0;
				z-index: 1000;
			}

			main {
				position: relative;
				height: calc(100vh - 60px);
				overflow-y: auto;
				width: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: flex-start;
			}
		}
	}
</style>
