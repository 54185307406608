<script lang="ts" setup>
	import useSizes from "~/composables/useSizes";
	import { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size?: Sizes;
		}>(),
		{
			size: "small",
		}
	);

	const { size } = toRefs(props);

	const { isSizeSmall, isSizeLarge } = useSizes(size);
</script>

<template>
	<svg class="icon-support" v-if="isSizeSmall" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="12" cy="12" r="11" stroke="black" stroke-width="2" />
		<path
			d="M11.0356 13.616C11.0356 13.1253 11.1369 12.6987 11.3396 12.336C11.5422 11.9733 11.9102 11.5573 12.4436 11.088C12.8169 10.7467 13.0836 10.4587 13.2436 10.224C13.4142 9.98933 13.4996 9.70667 13.4996 9.376C13.4996 8.96 13.3556 8.61867 13.0676 8.352C12.7902 8.08533 12.4062 7.952 11.9156 7.952C11.4462 7.952 11.0676 8.096 10.7796 8.384C10.4916 8.66133 10.2836 8.99733 10.1556 9.392L8.55556 8.72C8.7689 8.08 9.15823 7.51467 9.72356 7.024C10.2996 6.53333 11.0249 6.288 11.8996 6.288C12.5502 6.288 13.1316 6.42133 13.6436 6.688C14.1556 6.944 14.5502 7.30667 14.8276 7.776C15.1156 8.23467 15.2596 8.752 15.2596 9.328C15.2596 9.89333 15.1209 10.3787 14.8436 10.784C14.5769 11.1787 14.2196 11.5893 13.7716 12.016C13.4196 12.336 13.1689 12.6293 13.0196 12.896C12.8809 13.1627 12.8116 13.4667 12.8116 13.808V14.272H11.0356V13.616ZM11.9156 18.096C11.5742 18.096 11.2809 17.9787 11.0356 17.744C10.8009 17.4987 10.6836 17.2053 10.6836 16.864C10.6836 16.5227 10.8009 16.2347 11.0356 16C11.2809 15.7653 11.5742 15.648 11.9156 15.648C12.2569 15.648 12.5449 15.7653 12.7796 16C13.0142 16.2347 13.1316 16.5227 13.1316 16.864C13.1316 17.2053 13.0142 17.4987 12.7796 17.744C12.5449 17.9787 12.2569 18.096 11.9156 18.096Z"
			fill="black"
		/>
	</svg>
</template>

<style scoped lang="scss">
	.icon-support {
		circle,
		path {
			transition: all 0.2s ease-out;
		}
	}
</style>
