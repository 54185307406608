<script lang="ts" setup>
	import { useUser } from "~/stores/user";

	const route = useRoute();

	const { user } = useUser();

	const logout = computed(() => {
		return "/auth/logout";
	});

	const title = computed(() => {
		if (route.path.includes("companies")) {
			return "Companies";
		} else if (route.path.includes("contacts")) {
			return "Contacts";
		} else if (route.path.includes("enrichment")) {
			return "Enrichment";
		} else if (route.path.includes("exports")) {
			return "Exports";
		} else if (route.path.includes("support")) {
			return "Support";
		} else if (route.path.includes("account")) {
			return "Account";
		}

		return route.name;
	});
</script>

<template>
	<header class="header">
		<h1>{{ title }}</h1>

		<div class="profile">
			<h4>{{ user?.credits.toLocaleString() }} Credits</h4>
			<hr />
			<div class="button">
				<h4>{{ user?.first_name }} {{ user?.last_name }}</h4>

				<profile-picture size="small" :url="user?.profile_picture" :name="user!.first_name + ' ' + user!.last_name"></profile-picture>

				<ul class="shadow-normal border-grey">
					<li>
						<NuxtLink to="/account/profile">
							<h3>Profile</h3>
						</NuxtLink>
					</li>
					<li>
						<NuxtLink to="/account/billing">
							<h3>Billing</h3>
						</NuxtLink>
					</li>
					<li>
						<NuxtLink to="/account/invoices">
							<h3>Invoices</h3>
						</NuxtLink>
					</li>
					<li>
						<NuxtLink to="/account/credit-history">
							<h3>Credit History</h3>
						</NuxtLink>
					</li>
					<li>
						<a :href="logout">
							<h3>Log out</h3>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</header>
</template>

<style scoped lang="scss">
	.header {
		display: flex;
		flex-direction: row;
		background: white;
		width: 100%;
		height: 60px;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $grey;
		padding: 0 30px;

		h1 {
			user-select: none;
		}

		.profile {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 100%;

			h4 {
				user-select: none;
			}

			hr {
				height: 25px;
				border-right: 1px solid $grey;
				border-left: none;
				margin-left: 15px;
			}

			.button {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				height: 100%;
				padding: 0 15px;
				margin-right: -15px;
				position: relative;
				gap: 10px;
				cursor: pointer;

				ul {
					right: 0;
					list-style: none;
					position: absolute;
					top: 50px;
					width: 210px;
					overflow: hidden;
					border-radius: 16px;
					background: white;
					display: flex;
					flex-direction: column;
					pointer-events: none;
					opacity: 0;
					transition: all 0.2s ease-in-out;
					transform: translateY(-5px);

					li {
						a {
							display: flex;
							height: 50px;
							padding-left: 20px;
							align-items: center;
							transition: all 0.2s ease-in-out;
						}
						a:hover {
							background: $grey-light;
						}
						a.router-link-exact-active {
							background: $orange-semi;
							h3 {
								color: $orange;
							}
						}
					}
					li + li {
						border-top: 1px solid $grey;
					}
				}
			}

			.button:hover {
				ul {
					pointer-events: all;
					opacity: 1;
					transform: translateY(0px);
				}
			}
		}
	}
</style>
