<script lang="ts" setup></script>

<template>
	<nav class="navigation">
		<div class="header">
			<NuxtLink to="/companies">
				<logo />
			</NuxtLink>
		</div>

		<ul>
			<li>
				<NuxtLink to="/companies">
					<div class="icon"><NavigationCompaniesIcon /></div>
					<h3>Companies</h3>
				</NuxtLink>
			</li>
			<li>
				<NuxtLink to="/contacts">
					<div class="icon">
						<NavigationContactsIcon />
					</div>
					<h3>Contacts</h3>
				</NuxtLink>
			</li>
			<!-- <li>
				<NuxtLink to="/enrichment">
					<div class="icon"><NavigationEnrichmentIcon /></div>
					<h3>Enrichment</h3>
				</NuxtLink>
			</li> -->
			<li>
				<NuxtLink to="/exports">
					<div class="icon"><NavigationExportsIcon /></div>
					<h3>Exports</h3>
				</NuxtLink>
			</li>
			<li>
				<NuxtLink to="/support">
					<div class="icon"><NavigationSupportIcon /></div>
					<h3>Support</h3>
				</NuxtLink>
			</li>
		</ul>
	</nav>
</template>

<style scoped lang="scss">
	.navigation {
		user-select: none;
		display: flex;
		width: 260px;
		flex-direction: column;
		border-right: 1px solid $grey;
		background: white;

		.header {
			display: flex;
			align-items: center;
			padding-left: 30px;
			height: 60px;
			border-bottom: 1px solid $grey;
		}

		ul {
			display: flex;
			flex-direction: column;
			padding: 15px;
			list-style: none;
			li {
				border: 1px solid white;
				a {
					display: flex;
					align-items: center;
					border-radius: 12px;
					overflow: hidden;
					flex-direction: row;
					gap: 10px;
					padding: 15px 20px;
					transition: all 0.2s ease-out;
					.icon {
						display: flex;
						width: 28px;
						height: 28px;
						align-items: center;
						justify-content: center;
					}
					h3 {
						transition: all 0.2s ease-out;
					}
				}
				a:hover {
					background-color: $grey-light;
				}
				a.router-link-active {
					background-color: $red-light;
					h3 {
						color: $red;
					}
					:deep(svg.icon-companies) {
						rect {
							fill: $red;
						}
						path {
							stroke: $red;
						}
					}

					:deep(svg.icon-contacts) {
						circle {
							stroke: $red;
						}
						path {
							fill: $red;
						}
					}

					:deep(svg.icon-enrichment) {
						rect {
							fill: $red;
						}
						path:nth-child(1),
						path:nth-child(2) {
							fill: $red;
						}
						path:nth-child(3) {
							stroke: $red;
						}
					}

					:deep(svg.icon-exports) {
						rect,
						path {
							fill: $red;
						}
					}

					:deep(svg.icon-support) {
						circle {
							stroke: $red;
						}
						path {
							fill: $red;
						}
					}
				}
			}
		}
	}
</style>
